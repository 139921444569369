$(document).on('turbolinks:load', function () {

    $('.video-chapter').on('click', function () {
        var video = $(this).attr('video');
        var props = $(this).attr('video-props');

        $('.video-title').removeClass('psych');
        $('.video-chapter').removeClass('current');
        $(this).addClass('current');
        $(this).prevAll('.video-title').eq(0).addClass('psych');

        $('#videoplayer').attr('src', video)
        $('#videoplayer').trigger('play');
    })
    $('.video-title').on('click', function () {
        var video = $(this).attr('video');
        var props = $(this).attr('video-props');
        $('.video-title').removeClass('psych');
        $('.video-chapter').removeClass('current');
        $(this).next( ".video-chapter" ).addClass('current');
        $(this).addClass('psych');

        $('#videoplayer').attr('src', video)
        $('#videoplayer').trigger('play');
    })    
    $('#mobile-video-select').change(function () {

        var video = $( "#mobile-video-select option:selected" ).attr('video');
        var props = $( "#mobile-video-select option:selected" ).attr('video-props');
        $('#videoplayer').attr('src', video)
        $('#videoplayer').trigger('play');
    });
})
